import { defineStore } from 'pinia';
import { client } from '@/apollo';
import { graphql } from '@/gql';
import {
  Eatfresh_Order_Status_Enum,
  Eatfresh_Payment_Status_Enum,
  type Fetch_Customer_Orders_QueryQuery,
  type RfidFragment,
} from '@/gql/graphql';
import { ORDER_FRAGMENT } from '@/graphql/fragments';
import { ApiRequest } from './shared_store';
import { gql } from '@apollo/client/core';
import { useAuthStore } from '@/stores/auth';

type Order = Fetch_Customer_Orders_QueryQuery['eatfresh_customer_order'][0];

interface OrderStoreState {
  isLoading: boolean;
  totalOrderCount: number;
  orders: Fetch_Customer_Orders_QueryQuery['eatfresh_customer_order'];
}
interface GraphQlWhereClause {
  [key: string]: {
    [key: string]: string;
  };
}
export const FETCH_ORDERS_QUERY = graphql(`
  query FETCH_ORDERS_QUERY(
    $where: eatfresh_customer_order_bool_exp
    $pageSize: Int
    $offset: Int
  ) {
    eatfresh_customer_order(
      where: $where
      order_by: { user_id: asc }

      limit: $pageSize
      offset: $offset
    ) {
      ...OrderFragment
    }

    eatfresh_customer_order_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);
export const FETCH_CUSTOMER_ORDERS_QUERY = graphql(`
  query FETCH_CUSTOMER_ORDERS_QUERY(
    $user_ID: uuid!
    $pageSize: Int
    $offset: Int
  ) {
    eatfresh_customer_order(
      limit: $pageSize
      offset: $offset
      order_by: { created: desc }
      where: { user_id: { _eq: $user_ID } }
    ) {
      ...OrderFragment
    }
    eatfresh_customer_order_aggregate(where: { user_id: { _eq: $user_ID } }) {
      aggregate {
        count
      }
    }
  }
`);
export const FETCH_ORDER_BY_ID_QUERY = graphql(`
  query FETCH_ORDER_BY_ID_QUERY($order_ID: uuid!) {
    eatfresh_customer_order(where: { id: { _eq: $order_ID } }) {
      ...OrderFragment
    }
    eatfresh_customer_order_aggregate(where: { id: { _eq: $order_ID } }) {
      aggregate {
        count
      }
    }
  }
  ${ORDER_FRAGMENT}
`);
export const FETCH_ORDER_BY_ID_SUBSTRING_QUERY = graphql(`
  query FETCH_ORDER_BY_ID_SUBSTRING_QUERY(
    $pageSize: Int
    $offset: Int
    $user_ID: uuid!
    $order_ID: uuid!
  ) {
    eatfresh_customer_order(
      limit: $pageSize
      offset: $offset
      order_by: { created: desc }
      where: { user_id: { _eq: $user_ID }, _and: { id: { _eq: $order_ID } } }
    ) {
      ...OrderFragment
    }
    eatfresh_customer_order_aggregate(
      where: { user_id: { _eq: user_ID }, _and: { id: { _eq: order_ID } } }
    ) {
      aggregate {
        count
      }
    }
  }
  ${ORDER_FRAGMENT}
`);
export const useOrderStore = defineStore({
  id: 'order',
  state: (): OrderStoreState => ({
    isLoading: false,
    totalOrderCount: 0,
    orders: [],
  }),
  getters: {},
  actions: {
    async fetchOrders({
      searchString = '',
      searchProperty = undefined,
      page = 1,
      pageSize = 10,
      allEntries = false,
    }: {
      page?: number;
      pageSize?: number;
      allEntries?: boolean;
      searchString?: string;
      searchProperty?: { property: string; value: string; matcher: string };
    }) {
      const whereClause: GraphQlWhereClause = {
        /*'id': {
          '_eq':searchString //'%'+searchString+'%'
        },*/
        //TODO how can we do an "ilike" partial check on the uuid for filtering?
      };
      if (searchProperty) {
        whereClause[searchProperty.property] = {
          [searchProperty.matcher]: searchProperty.value,
        };
      }
      this.isLoading = true;
      const offset = (page - 1) * pageSize;
      const orderQueryResult = await client().query({
        query: FETCH_ORDERS_QUERY,
        variables: {
          where: whereClause,
          offset: allEntries ? undefined : offset,
          pageSize: allEntries ? undefined : pageSize,
        },
      });
      this.orders = orderQueryResult.data.eatfresh_customer_order;
      this.totalOrderCount =
        orderQueryResult.data.eatfresh_customer_order_aggregate.aggregate
          ?.count ?? 0;
      this.isLoading = false;
    },

    async fetchCustomerOrders({
      page,
      pageSize,
      user_id,
    }: {
      page: number;
      pageSize: number;
      user_id: string;
    }) {
      this.isLoading = true;
      const offset = (page - 1) * pageSize;
      const orderQueryResult = await client().query({
        query: FETCH_CUSTOMER_ORDERS_QUERY,
        variables: {
          user_ID: user_id,
          offset,
          pageSize,
        },
      });
      this.orders = orderQueryResult.data.eatfresh_customer_order;
      this.totalOrderCount =
        orderQueryResult.data.eatfresh_customer_order_aggregate.aggregate
          ?.count ?? 0;
      this.isLoading = false;
    },

    async fetchOrderById(order_id: string) {
      this.isLoading = true;
      const query = gql`
        query FETCH_ORDER_BY_ID_QUERY($order_ID: uuid!) {
          eatfresh_customer_order(where: { id: { _eq: $order_ID } }) {
            ...OrderFragment
          }
          eatfresh_customer_order_aggregate(where: { id: { _eq: $order_ID } }) {
            aggregate {
              count
            }
          }
        }
      `;
      const orderQueryResult = await client().query({
        query: query,
        variables: {
          order_ID: order_id,
        },
      });
      //TODO why is return value sometimes undefined? Fix query!
      if (!orderQueryResult?.data?.eatfresh_customer_order) return;
      this.orders = orderQueryResult.data.eatfresh_customer_order;
      console.log(orderQueryResult.data);
      this.totalOrderCount =
        orderQueryResult.data.eatfresh_customer_order_aggregate.aggregate.count;
      this.isLoading = false;
    },

    async fetchOrdersByIdSubstring({
      page,
      pageSize,
      user_id,
      orderId,
    }: {
      page: number;
      pageSize: number;
      user_id: string;
      orderId: string;
    }) {
      this.isLoading = true;
      const offset = (page - 1) * pageSize;
      const orderQueryResult = await client().query({
        query: FETCH_ORDER_BY_ID_SUBSTRING_QUERY,
        variables: {
          order_ID: orderId,
          user_ID: user_id,
          offset,
          pageSize,
        },
      });
      this.orders = orderQueryResult.data.eatfresh_customer_order;
      this.totalOrderCount =
        orderQueryResult.data.eatfresh_customer_order_aggregate.aggregate.count;
      this.isLoading = false;
    },

    async fullfillOrder(order: Order, purchased_articles: RfidFragment[]) {
      return await ApiRequest<string>({
        url: '/rest/customer_order/fullfill',
        body: {
          order_id: order.id,
          purchased_articles: purchased_articles,
        },
      });
    },

    async createDraftOrder({
      user_id,
      given_id,
      demo_flag,
    }: CreateDraftOrderBody) {
      return await ApiRequest<CreateDraftOrderResponse>({
        url: '/rest/customer_order/create',
        body: {
          user_id,
          given_id: given_id,
          order_status: Eatfresh_Order_Status_Enum.Draft,
          payment_status: Eatfresh_Payment_Status_Enum.Pending,
          demo_flag: demo_flag,
        },
        options: { responseType: 'json' },
      });
    },

    async cancelOrder(orderId: string) {
      return await ApiRequest<CancelOrderResponse>({
        url: '/rest/customer_order/cancel',
        body: {
          id: orderId,
        },
        options: { responseType: 'json', keepAlive: true },
      });
    },

    async downloadArticleCSV(dates: [Date, Date] | null) {
      const authStore = useAuthStore();

      const response = await fetch('/rest/customer_order/download', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authStore.authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dates: dates,
        }),
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      let reportTitle = 'Tax_Report';
      if (dates && dates[0] && dates[1]) {
        const [start, end] = dates;
        const formattedStart = start.toLocaleDateString().replace(/\//g, '-');
        const formattedEnd = end.toLocaleDateString().replace(/\//g, '-');
        reportTitle += `_${formattedStart}_to_${formattedEnd}`;
      }

      a.download = reportTitle + '.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
      return true;
    },
  },
});

type CreateDraftOrderBody = {
  user_id: string;
  given_id: string;
  demo_flag?: boolean;
};

type CreateDraftOrderResponse = {
  message: string;
  order: {
    id: string;
  };
};

type CancelOrderResponse = {
  message: string;
  order: {
    id: string;
  };
};
