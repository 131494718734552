import { defineStore } from 'pinia';
import { useToast } from 'primevue/usetoast';

export const useSharedStore = defineStore({
  id: 'shared',
  state: () => ({
    ignoreBrowserReroute: false,
  }),
  getters: {},
  actions: {
    formatPrice({
      countryLocale,
      currency,
      amount,
    }: {
      countryLocale: string;
      currency: string;
      amount: number;
    }) {
      return new Intl.NumberFormat(countryLocale, {
        style: 'currency',
        currency: currency.toUpperCase(),
        signDisplay: 'never',
        currencyDisplay: 'symbol',
      }).format(amount / 100);
    },
    formatDate(date: string) {
      return new Date(date).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    createDebounce() {
      let timeout: ReturnType<typeof setTimeout> | undefined;
      return function (fnc: () => void, delayMs: number) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    log(input: any, logMessage?: string): void {
      console.log(logMessage, input);
    },
    validEmailStructure(email: string) {
      // const emailAddress: string = 'laurenz.morbe@gmail.com'
      const emailTestRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailTestRegex.test(email)) return false;
      const addressParts: string[] = email.split('@');
      // console.log(addressParts)
      if (addressParts.length > 2) return false; // only one '@' is allowed
      if (addressParts[0].length > 64) return false; // character limit for prefix is 64
      if (addressParts[1].length > 255) return false; // character limit for suffix is 255
      return true;
    },
    validNewPassword(password: string): boolean {
      if (password.length < 8) return false;
      const hasNumberRegex = /(?=.*[0-9])/;
      if (!hasNumberRegex.test(password)) return false;
      const hasUppercaseRegex = /(?=.*[A-Z])/;
      if (!hasUppercaseRegex.test(password)) return false;
      const hasLowercaseRegex = /(?=.*[a-z])/;
      if (!hasLowercaseRegex.test(password)) return false;
      return true;
    },
    randomNumber({ min, max }: { min: number; max: number }) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    ApiRequest,
  },
});
import { useAuthStore } from '@/stores/auth';

interface ApiRequestParameters<B = any> {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: B;
  options?: {
    useAuthorization?: boolean;
    responseType?: 'string' | 'json';
    headers?: Array<{ name: string; value: string }>;
    keepAlive?: boolean;
  };
}
interface ApiRequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers: Headers;
  body: undefined | string | FormData;
  keepAlive?: boolean;
}

export async function ApiRequest<
  T extends Record<string, unknown> | string,
  E extends { message: string } = { message: string },
  B = any,
>({ url, method, body, options }: ApiRequestParameters<B>) {
  const requestOptions: ApiRequestOptions = {
    method: method ?? 'POST',
    headers: new Headers(),
    body: undefined,
    keepAlive: undefined,
  };
  //headers
  if (body) {
    if (body instanceof FormData) {
      requestOptions.body = body;
    } else {
      requestOptions.headers.append('Content-Type', 'application/json');
      requestOptions.body = JSON.stringify(body);
    }
  }
  const useAuth = options?.useAuthorization ?? true;
  if (authHeader() && useAuth) {
    requestOptions.headers.append('Authorization', `Bearer ${authHeader()}`);
  }
  if (options?.headers) {
    options.headers.forEach((header) => {
      requestOptions.headers.append(header.name, header.value);
    });
  }
  if (options?.keepAlive !== undefined) {
    requestOptions.keepAlive = options.keepAlive;
  }
  const response = await fetch(url, requestOptions);
  return await handleRes<T>(response, options);
}

// helper functions

function authHeader() {
  // return auth header with jwt if user is logged in and request is to the api url
  const { authToken } = useAuthStore();
  const isLoggedIn = !!authToken;
  if (isLoggedIn) {
    return authToken;
  } else {
    return false;
  }
}

export class ParsingError extends Error {
  public reason: string;
  public row: number;
  public column: number;
  constructor(message: string, reason: string, row: number, column: number) {
    super(message);
    this.reason = reason;
    this.row = row;
    this.column = column;
  }
}

async function handleRes<T extends Record<string, unknown> | string>(
  res: Response,
  options: ApiRequestParameters['options'],
): Promise<{ value: T; response: Response }> {
  const responseType = options?.responseType ?? 'string';
  if (!res.ok) {
    const error = await res.json();
    if (error.errorType === 'ParsingError') {
      console.log('message:', error.message);
      console.log('reason:', error.reason);
      throw new ParsingError(
        error.message,
        error.reason,
        error.row,
        error.column,
      );
    }
    throw new Error(error.message ?? 'Something went wrong');
  }
  return {
    value:
      responseType === 'string'
        ? ((await res.text()) as T)
        : ((await res.json()) as T),
    response: res,
  };
}

export async function googleLog(
  message: string,
  severity: 'debug' | 'warn' | 'info' | 'error' | 'fatal' = 'info',
) {
  const auth = useAuthStore();
  const type = auth.authToken
    ? auth.getUserRoleFromToken(auth.authToken)
    : undefined;
  return await ApiRequest<string>({
    method: 'POST',
    url: '/rest/google_alert/create',
    body: {
      basic_user_id: auth.id ?? undefined,
      basic_user_type: type,
      severity,
      message,
    },
  });
}
export function formatEUDate(date: string) {
  return new Date(date).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
export async function createSessionStepMetric(
  step: string,
  data?: {
    user_id?: string | null;
    sent_from?: string;
    sent_at?: string;
    reset_session?: boolean;
  },
) {
  let session_id = localStorage.getItem('session_id');
  if (!session_id || session_id === '') {
    session_id = crypto.randomUUID();
    localStorage.setItem('session_id', session_id);
  }
  data = data || {};
  if (!data.sent_from) {
    const userAgent = navigator?.userAgent || 'Unknown User Agent';
    const platform = navigator?.platform || 'Unknown Platform';
    const language = navigator?.language || 'Unknown Language';
    data.sent_from = `${userAgent}; Platform: ${platform}; Language: ${language}`;
  }
  if (!data.sent_at) {
    data.sent_at = new Date().toISOString();
  }
  if (!data.user_id) {
    data.user_id = useAuthStore().id;
  }
  if (data.reset_session) {
    localStorage.removeItem('session_id');
  }
  return await ApiRequest<string>({
    method: 'POST',
    url: '/rest/metrics/create/session-step',
    body: {
      session_id,
      step,
      user_id: data.user_id,
      sent_from: data.sent_from,
      sent_at: data.sent_at,
    },
  });
}
