import { graphql } from '@/gql';

export const ORDER_FRAGMENT = graphql(`
  fragment OrderFragment on eatfresh_customer_order {
    created
    gross_total_sum
    total_sum
    id
    payment_status
    payment_method
    order_status
    updated
    user_id
    longitude
    latitude
    trans_id
  }
`);

export const USER_FRAGMENT = graphql(`
  fragment UserFragment on eatfresh_user_account {
    active
    basic_user_type
    created
    email
    first_name
    last_name
    id
    last_login
    requires_password_change
    updated
    active
    agb_accepted
    stripe_customer_id
    biometrics
    current_challenge
    has_password
  }
`);
export const USER_FRAGMENT_ANONYMOUS = graphql(`
  fragment UserFragmentAnonymous on eatfresh_user_account {
    email
    id
    biometrics
    current_challenge
    has_password
  }
`);

export const ANONUSER_FRAGMENT = graphql(`
  fragment AnonUserFragment on eatfresh_user_account {
    email
    id
    biometrics
    current_challenge
    has_password
  }
`);

export const RFID_FRAGMENT = graphql(`
  fragment Rfid on eatfresh_rfid_tag {
    best_before_date

    rfid_asset {
      id
      asset_placement {
        ...AssetPlacement
      }
    }
    id
    rfid_asset_id
    rfid_number
    article_id
    rfid_status
    created
    fridge_id
    article {
      id
      name
      ean
      product_image_id
      supplier
      uploaded_file {
        url
      }
    }
  }
`);

export const ARTICLE_FRAGMENT = graphql(`
  fragment Article on eatfresh_article {
    id
    active
    created
    description
    ean
    id
    name
    product_image_id
    supplier
    updated
    wholesale_price
    retail_price
    article_detail {
      ...ArticleDetails
    }
    uploaded_file {
      file_type
      url
      name
      id
    }
  }
`);

export const NUTRITION_INFORMATION_FRAGMENT = graphql(`
  fragment NutritionInformation on eatfresh_nutrition_information {
    id
    nutritional_values_unit
    calories_kcal
    calories_kj
    fat_g
    salt_g
    saturated_fat_g
    carbohydrates_g
    sugar_g
    fiber_g
    protein_g
  }
`);

export const VITAMIN_MINERAL_FRAGMENT = graphql(`
  fragment VitaminMineral on eatfresh_vitamin_mineral {
    id
    name
    weight
    percentage
    article_details_id
  }
`);

export const VAT_CATEGORY_FRAGMENT = graphql(`
  fragment VatCategory on eatfresh_vat_category {
    id
    name
    rate
  }
`);
export const PASSWORD_RESET_TOKEN_FRAGMENT = graphql(`
  fragment PasswordResetToken on eatfresh_password_reset_token {
    id
    user_id
    user_email
    token
    status
    created_at
    expires_at
    used
  }
`);

export const ARTICLE_DETAILS_FRAGMENT = graphql(`
  fragment ArticleDetails on eatfresh_article_details {
    id
    article_id
    name
    purchase_price
    recommended_price
    article_number
    gln
    ifs_broker_certified
    co_id
    origin_country
    remaining_shelf_life
    total_mhd
    min_storage_temperature
    max_storage_temperature
    ean
    packaging_type
    net_fill_volume
    net_weight
    gross_weight_single_item
    width_single_item
    height_single_item
    depth_single_item
    gtin_package_unit
    packaging_type_package_unit
    items_per_package_unit
    gross_weigth_package_unit
    width_package_unit
    height_package_unit
    depth_package_unit
    is_deposit_required
    returnable
    deposit_value_single_item
    deposit_value_package_unit
    alcohol_content
    designation_according_to_lmiv
    ingredient_list
    fruit_content_percentage
    preservatives
    stabilizers
    dyes_coloring
    flavor_enhancer
    other_additives
    contains_gluten
    contains_eggs
    contains_peanuts
    contains_milk
    contains_crustaceans
    contains_fish
    contains_soy
    contains_lactose
    may_contain_traces_of
    bio_control_number
    regional
    eu_approval_number
    vegan
    vegetarian
    kosher
    storage_box_capacity
    halal
    storage_box_capacity
    nutrition_information {
      ...NutritionInformation
    }
    vitamin_minerals {
      ...VitaminMineral
    }
    vat_category {
      ...VatCategory
    }
  }
`);

export const RFID_SCANNER_FRAGMENT = graphql(`
  fragment RfidScanner on eatfresh_rfid_scanner {
    id
    given_id
    name
    type
  }
`);

export const RFID_BOX_FRAGMENT = graphql(`
  fragment RfidBox on eatfresh_rfid_box {
    id
    type
  }
`);

export const RFID_ASSET_FRAGMENT = graphql(`
  fragment RfidAsset on eatfresh_rfid_asset {
    id
    type
    epc
    labeling_id
  }
`);

export const RFID_SCAN_FRAGMENT = graphql(`
  fragment RfidScan on eatfresh_rfid_scan {
    id
    rfid_scanner_id
    scanned_at
    epcs
    status
    error
    reading_time
    reading_time_left
    read_finished
    scan_id
  }
`);

export const BOX_CONTENT_PLAN_FRAGMENT = graphql(`
  fragment BoxContentPlan on eatfresh_box_content_plan {
    id
    box_id
    labeling_id
    commissioning_id
    status
    shortcode
    package_id
    confirmed_at
    box_content_to_articles {
      id
      article_id
      quantity
      article {
        ...Article
      }
    }
  }
`);

export const PLACE_FRAGMENT = graphql(`
  fragment Place on eatfresh_place {
    id
    name
    longitude
    latitude
    description
    directions_pdf_id
    directions_summary
    google_maps_url
    street
    nb
    city
    zip_code
    country
    additional_line
    location_name
    updated
    created
    last_sync

    uploaded_file {
      id
      name
      url
    }
  }
`);

export const WAREHOUSE_FRAGMENT = graphql(`
  fragment Warehouse on eatfresh_warehouse {
    created_at
    id
    name
    updated_at
    place {
      ...Place
    }
  }
`);

export const WAREHOUSE_ORDER_FRAGMENT = graphql(`
  fragment WarehouseOrder on eatfresh_warehouse_order {
    id
    warehouse_order_status
    warehouse_id
    supplier
    updated_at
    created_at
  }
`);

export const FRIDGE_TARGET_STOCK_FRAGMENT = graphql(`
  fragment FridgeTargetStock on eatfresh_fridge_target_stock {
    id
    fridge_id
  }
`);

export const FRIDGE_TARGET_STOCK_POSITION_FRAGMENT = graphql(`
  fragment FridgeTargetStockPosition on eatfresh_fridge_target_stock_position {
    id
    article_id
    quantity
  }
`);

export const FRIDGE_FRAGMENT = graphql(`
  fragment Fridge on eatfresh_fridge {
    id
    given_id
    connection_status
    door_status
    disabled
    type
    demo_flag
    directions_summary
    place {
      ...Place
    }
  }
`);

export const FRIDGE_ORDER_FRAGMENT = graphql(`
  fragment FridgeOrder on eatfresh_fridge_order {
    id
    fridge_id
    priority
    active
    last_serviced_at
  }
`);

export const COMMISSIONING_FRAGMENT = graphql(`
  fragment CommissioningProcess on eatfresh_commissioning_process {
    id
    fridge_order_id
    scanner_id
    user_id
    status
  }
`);

export const BOX_CONTENT_PLAN_WITH_BOX_FRAGMENT = graphql(`
  fragment BoxContentPlanWithBox on eatfresh_box_content_plan {
    ...BoxContentPlan
    rfid_scans_aggregate {
      aggregate {
        max {
          scanned_at
        }
      }
    }

    rfid_tags {
      id
      rfid_number
    }

    rfid_box {
      ...RfidBox
      asset_placements {
        rfid_asset {
          ...RfidAsset
          rfid_tags {
            ...Rfid
          }
        }
      }
      rfid_asset {
        ...RfidAsset
        asset_placement {
          ...AssetPlacement
          warehouse_shelf {
            ...WarehouseShelf
          }
        }
      }
    }
  }
`);

export const ASSET_PLACEMENT_FRAGMENT = graphql(`
  fragment AssetPlacement on eatfresh_asset_placement {
    id
    rfid_asset_id
    fridge_id
    place_id
    warehouse_id
    shelf_id
    rfid_box_id
    labeling_id
    commissioning_id
    refill_id
  }
`);

export const WAREHOUSE_SHELF_FRAGMENT = graphql(`
  fragment WarehouseShelf on eatfresh_warehouse_shelf {
    id
    type
    shelf_row
    shelf_column
  }
`);